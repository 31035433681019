import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.png";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={[classes.footerWrap, 'mt'].join(' ')}>
      <div className="container">
        <div className={classes.footer}>
          <div className={classes.logo}>
            <img src={logo} alt=''/>
          </div>
          <div className={classes.info}>
            <div className={classes.contacts}>
              <p>
                {t('footer_contacts')}
              </p>
              <a href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
              <a href="tel:+442081575101">
                +442081575101
              </a>
              <a href="tel:+48696041823">
                +48696041823
              </a>
              <a href="tel:+420790285555">
                +420790285555
              </a>
            </div>
            <div className={classes.docs}>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                {t('footer_docs_terms')}
              </a>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                {t('footer_docs_privacy')}
              </a>
              <a href="/docs/AML_POLICY.pdf" target="_blank">
                {t('footer_docs_aml')}
              </a>
              <a href="/docs/KYC_POLICY.pdf" target="_blank">
                KYC
              </a>
              <a href="https://find-and-update.company-information.service.gov.uk/company/03463250/officers" target="_blank" rel="noreferrer">
                Regulation
              </a>
            </div>
          </div>
          <div className={classes.disclaimer}>
            <p className={classes.disclaimerTitle}>
              {t('footer_disclaimer_title')}
            </p>
            <p>
              <Trans>
                {t('footer_disclaimer_text')}
              </Trans>
            </p>
            <p className={classes.rights}>
              {t('footer_rights')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
